import React, { useEffect, useRef, useState } from 'react'
import EmailValidator from 'email-validator'
import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'
import Button from 'src/components/button'

import Countries from 'src/data/Countries'

// Styles & Images:
import 'src/components/pages/vip/scss/FormModule.scss'

import Background from 'src/components/pages/vip/img/form-background.png'

// Partials:
const Wrapper = ({ children }) => (
  <div id="sign-up" className="x__form-module x__form-module__wrapper">
    {children}
  </div>
)

const FormMessage = ({ copy }) => <p className="x__form-module__message">{copy}</p>

const Input = ({ defaultValue = '', name, options, type = 'text', onChange }) => {
  const inputRef = useRef(null)

  const [value, setValue] = useState('')

  const handleInputChange = () => {
    setValue(inputRef.current.value)
  }

  const handleInputClick = () => {
    !options?.length && setValue('')
  }

  const handleInputBlur = () => {
    if (inputRef.current.value === '') {
      if (options) setValue(defaultValue)
      else setValue('')
    }
  }

  useEffect(() => {
    if (options) setValue(defaultValue)
  }, [options, defaultValue])

  useEffect(() => {
    if (value === defaultValue) return null
    onChange(value)
  }, [value, defaultValue])

  const props = {
    name,
    value,
    ref: inputRef,
    className: classNames('x__input', { 'x__input--default': value === defaultValue }),
    onKeyUp: () => handleInputChange(),
    onChange: () => handleInputChange(),
    onClick: () => handleInputClick(),
    onBlur: () => handleInputBlur(),
  }

  if (!name) return null

  if (options) {
    return (
      <select {...props}>
        <option disabled value="Country of Residence">
          {defaultValue}
        </option>

        {options.map(({ name, code }) => (
          <option key={code} value={name}>
            {name}
          </option>
        ))}
      </select>
    )
  }

  if (name === 'message') {
    return <textarea {...props} placeholder={defaultValue} />
  }

  return <input {...props} type={type} placeholder={defaultValue} />
}

const SuccessMessage = ({ copy }) => (
  <div className="x__form-module__success">
    <span>{copy}</span>
  </div>
)

// Main component:
const FormModule = ({ page, data, state, onSubmit }) => {
  const [values, setValues] = useState({})
  const [formState, setFormState] = useState(state)
  const [disabled, setDisabled] = useState(true)

  const handleInputChange = (key, value) => {
    setValues({ ...values, [key]: value })
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    onSubmit(values)
  }

  useEffect(() => {
    const inputValidation =
      !values.email ||
      values.email === ' ' ||
      !EmailValidator.validate(values.email) ||
      !values.firstName ||
      values.firstName === ' ' ||
      !values.lastName ||
      values.lastName === ' ' ||
      !values.country ||
      values.country === 'Country of Residence' ||
      !values.message ||
      values.message === ' '

    setDisabled(inputValidation)
  }, [values])

  useEffect(() => {
    setFormState(state)
  }, [state])

  if (!data.inputs.length) return null

  return (
    <Wrapper>
      <Heading page={page} section="form" type="h2" />
      {data.description.map((desc, d) => (
        <p className="x-header__description" key={d}>
          {desc}
        </p>
      ))}
      {formState === 'success' ? (
        <SuccessMessage copy={data.success} />
      ) : (
        <form>
          {data.inputs.map(({ name, defaultValue, type }) => (
            <Input
              key={name}
              name={name}
              type={type}
              defaultValue={defaultValue}
              options={name === 'country' ? Countries : null}
              onChange={(value) => handleInputChange(name, value)}
            />
          ))}

          {formState !== '' && <FormMessage copy={data[formState]} />}

          <Button
            copy={formState === 'loading' ? data.submitting : data.submit}
            disabled={formState === 'loading' || formState === 'error' || disabled}
            size="largest"
            onClick={handleSubmitClick}
          />
        </form>
      )}

      <img className="x__form-module__background" src={Background} />
    </Wrapper>
  )
}

export default FormModule
