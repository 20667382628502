import React, { useState } from 'react'
import classNames from 'classnames'

import Heading from 'src/components/heading/Heading'

import { chunkArray } from 'src/utils'

// Styles & Images:
import 'src/components/pages/vip/scss/FaqSection.scss'

// Partials:
const ArrowIcon = () => (
  <span className="x__accordion__arrow">
    <svg viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.3" d="M1 0.999817L7 6.99982L1 12.9998" stroke="white" strokeWidth="2" />
    </svg>
  </span>
)

const Accordion = ({ label, description }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={classNames('x__accordion', { 'x__accordion--open': open })}>
      <span className="x__accordion__label" onClick={() => setOpen(!open)} tabIndex={0}>
        {label}

        <ArrowIcon />
      </span>

      <p className="x__accordion__content" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  )
}

// Main component:
const FaqSection = ({ page, data, onSignUpClick }) => {
  const { items } = data
  const itemCount = !!items && items.length
  const columns = !!items && chunkArray(items, Math.ceil(itemCount / 2))

  if (!itemCount) return null

  return (
    <section className="x__faq-section">
      <Heading page={page} section="faq" type="h2" />

      <div className="x__content">
        {columns.map((colItems, col) => (
          <div key={`col--${col}`} className="x__col">
            {colItems.map(({ label, description }, i) => (
              <Accordion key={i} label={label} description={description} />
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}

export default FaqSection
