import React from 'react'

import Heading from 'src/components/heading/Heading'
import Button from 'src/components/button'

// Styles & Images:
import 'src/components/pages/vip/scss/RefundSection.scss'

// Main component:
const RefundSection = ({ page, data, onSignUpClick }) => (
  <section className="x__refund-section">
    <div className="x__content">
      <div className="x-header__container">
        <div className="x__col">
          <Heading page={page} section="refund" type="h2" />
          {data.description.map((desc, d) => (
            <p className="x-header__description" key={d}>
              {desc}
            </p>
          ))}
          <Button copy={data.signup} size="largest" onClick={onSignUpClick} />
        </div>

        <div className="x__col">
          <div className="x__content-wrapper">
            <h3 className="x__content__heading">{data.subheading}</h3>
            <ul className="x__content__list">
              {!!data.items.length &&
                data.items.map((item, i) => (
                  <li key={i} className="x__content__list-item">
                    <span>{i + 1}</span>
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default RefundSection
