// dependencies.
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import EmailValidator from 'email-validator'
import Img from 'gatsby-image'
// components.
import Button from 'src/components/button'
import CarouselModule from 'src/components/pages/vip/CarouselModule'
import DownloadSection from 'src/components/pages/shared/DownloadSection'
import FaqSection from 'src/components/pages/vip/FaqSection'
import FormModule from 'src/components/pages/vip/FormModule'
import PageHead from 'src/components/head/PageHead'
import Heading from 'src/components/heading/Heading'
import PricingModule from 'src/components/pages/vip/PricingModule'
import RefundSection from 'src/components/pages/vip/RefundSection'
import ZoomImage from 'src/components/pages/vip/ZoomImage'
import { Header, Footer } from 'src/components/layout'
// utils.
import { useRedditPixel, useMetaPixel, useTikTokPixel } from 'src/components/head/Analytics'
import { useRellax, useScrollToAnchor } from 'src/js/utils/hooks'
import buildImageObject from 'src/js/utils/buildImgObject'
import logEvent from 'src/js/analytics'
// constants.
import { HELPERS_URL, VIP_SUPPORT_PLUS_ENABLED } from 'src/constants'
import { MARCOM_SUBSCRIBE_CS_MEMBERSHIP_PAGE } from 'src/js/analytics/trackers'
// data.
import VipData from 'src/data/pages/Contents/vip.json'

// Styles & Images
import 'static/vip/scss/styles.scss'

const page = 'vip'
const data = VipData

const images = {
  headerImage: {
    aspectRatio: 1.35,
    alt: 'Exodus empowers people to control their wealth',
    path: '/vip/img/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1516,
      },
      {
        fileName: 'header-bg-lsize',
        width: 2400,
      },
    ],
  },
}

// Helpers:

// Partials:
const BackgroundImage = ({ image, speed }) => (
  <div
    className={classNames('x__background-container', { 'vip-rellax': speed })}
    data-rellax-speed={speed}
  >
    <div className="x__background">
      <Img fluid={buildImageObject(image)} alt={image.alt} durationFadeIn={2000} />
    </div>
  </div>
)

const ChromeImage = ({ index, containerClassName, speed }) => (
  <span
    className={classNames(`${containerClassName}__chrome ${containerClassName}__chrome--${index}`, {
      'vip-rellax': speed,
    })}
    data-rellax-speed={speed}
    role="presentation"
  />
)

const HeaderSection = ({ data, onSignUpClick }) => {
  return (
    <header className="x-header">
      <div className="x__content">
        <div className="x-header__container">
          <div className="x-header__container-headings vip-rellax" data-rellax-speed="3">
            <Heading page={page} />
            <Heading page={page} section="header" type="h2" />
            <Button copy={data.cta.copy} onClick={onSignUpClick} size="largest" />
          </div>

          <div className="x-header__container-content">
            <ZoomImage />
            <PricingModule
              price={data.plan.price}
              oldPrice={data.plan.old_price}
              duration={data.plan.duration}
              items={data.plan.features}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

const Web3Section = ({ data }) => (
  <section className="x-web3">
    <div className="x__content">
      <div className="x-header__container">
        <div className="x__col">
          <Heading page={page} section="web3" type="h2" />
        </div>

        <div className="x__col">
          {data.description.map((desc, d) => (
            <p className="x-header__description" key={d}>
              {desc}
            </p>
          ))}
        </div>
      </div>
    </div>

    <ChromeImage index={1} containerClassName="x-web3" speed={0.5} />
    <ChromeImage index={2} containerClassName="x-web3" speed={1.0} />
    <ChromeImage index={3} containerClassName="x-web3" speed={1.5} />
  </section>
)

const TermsSection = ({ data }) => (
  <section className="x-terms">
    {data.links.map((link, l) => (
      <a key={l} target="_blank" rel="noreferrer noopener" href={link.href}>
        {link.title}
      </a>
    ))}
  </section>
)

// Main Component:
const VipPage = () => {
  const [anchorClicked, setAnchoClicked] = useState(false)
  const [formState, setFormState] = useState('')

  useRellax({ className: 'vip-rellax', center: false })

  /* initialize Pixels */
  useRedditPixel()
  useMetaPixel()
  useTikTokPixel()

  useScrollToAnchor(anchorClicked, 'sign-up')

  /* subscribe */
  const handleSubmitClick = (values) => {
    setFormState('loading')
    /* At least one origin needs to be checked */
    if (
      !values.email &&
      !values.firstName &&
      !values.lastName &&
      !values.country &&
      !values.message
    ) {
      setFormState('error')
      return
    }
    /* Validate 'email' field */
    if (!!values.email && !EmailValidator.validate(values.email)) {
      setFormState('error')
      return
    }
    /*
     * Submitting
     */
    fetch(`${HELPERS_URL}/users/vip-subscription`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const success = response.status === 200

        if (success) {
          /* Notice success */
          logEvent(MARCOM_SUBSCRIBE_CS_MEMBERSHIP_PAGE)
          setFormState('success')
        } else {
          /* Notice if subscription fails */
          setFormState('error')
        }
      })
      .catch((err) => {
        /* Notice if subscription fails globally */
        console.log(err)
        setFormState('error')
      })
  }

  // redirect to /contact-support if Support+ is disabled.
  useEffect(() => {
    if (!VIP_SUPPORT_PLUS_ENABLED) {
      if (typeof window === 'undefined') return // prevent build from failing.
      window.location.href = '/contact-support/'
    }
  }, [VIP_SUPPORT_PLUS_ENABLED])

  // Do not return content.
  if (!VIP_SUPPORT_PLUS_ENABLED) return null

  // Return content.
  return (
    <div className="x-page-vip">
      <Header isBannerHidden />

      <main className="x">
        <BackgroundImage image={images.headerImage} speed={-4} />
        <HeaderSection data={data.header} onSignUpClick={() => setAnchoClicked(true)} />
        <Web3Section data={data.web3} />
        <FormModule
          page={page}
          data={data.form}
          state={formState}
          onSubmit={(values) => handleSubmitClick(values)}
        />
        <CarouselModule data={data.carousel} />

        <RefundSection page={page} data={data.refund} onSignUpClick={() => setAnchoClicked(true)} />
        <FaqSection page={page} data={data.faq} />
        <TermsSection data={data.terms} />

        <DownloadSection
          page={page}
          section="download"
          copy={data.download.copy}
          path={data.download.path}
        />

        <Footer enableSubscribeNotice={false} noBorder />
      </main>
    </div>
  )
}

export default VipPage

// <head> component:
export function Head() {
  return <PageHead page={page} />
}
