import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { useWindowEvent } from 'src/js/utils/hooks'

// Styles & Images
import 'src/components/pages/vip/scss/CarouselModule.scss'

// Partials:
const ArrowIcon = ({ direction, disabled, onClick }) => {
  const handleOnClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <a
      className={classNames(`x__carousel-module__arrow x__carousel-module__arrow--${direction}`, {
        'x__carousel-module__arrow--disabled': disabled,
      })}
      tabIndex={0}
      onClick={(e) => handleOnClick(e)}
    >
      <svg viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" d="M1 0.999817L7 6.99982L1 12.9998" stroke="white" strokeWidth="2" />
      </svg>
    </a>
  )
}

const Item = ({ label, copy, path, width }) => (
  <div className="x__item" style={{ width }}>
    <a href={path} target="_blank">
      <p className="x__item__label">{label}</p>
      <p className="x__item__copy" dangerouslySetInnerHTML={{ __html: copy }} />
    </a>
  </div>
)

// Main component:
const CarouselModule = ({ data, visibleItemsCount = 3 }) => {
  const { items } = data

  const carouselRef = useRef(null)
  const timeoutRef = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  /* media-queries */
  const checkForMobile = () => {
    if (typeof window === 'undefined') return
    setIsMobile(window.innerWidth < 768)
  }
  useWindowEvent('resize', checkForMobile)
  useEffect(() => checkForMobile(), [isMobile])

  /* handle item change */
  const handlePrevClick = () => {
    if (activeIndex === 0) return null
    setActiveIndex(isMobile ? activeIndex - 1 : activeIndex - visibleItemsCount)
  }

  const handleNextClick = () => {
    if (isMobile) {
      if (activeIndex === items.length - 1) {
        setActiveIndex(0)
        return null
      }
      setActiveIndex(activeIndex + 1)
    } else {
      if (activeIndex === items.length - visibleItemsCount) {
        setActiveIndex(0)
        return null
      }
      setActiveIndex(activeIndex + visibleItemsCount)
    }
  }

  const handleIndexClick = (index) => {
    if (index === items.length) return null
    setActiveIndex(isMobile ? index : index * visibleItemsCount)
  }

  /* set carousel & item size */
  const setCarouselWidth = () => {
    if (carouselRef.current === null) return
    const carouselWidth = carouselRef.current.clientWidth
    const visibleItems = isMobile ? 1 : visibleItemsCount
    setItemWidth(carouselWidth / visibleItems)
  }

  useWindowEvent('resize', setCarouselWidth)

  useEffect(() => {
    setCarouselWidth()
  }, [carouselRef, visibleItemsCount, isMobile])

  /* create slides array */
  const slidesIndex = Array.from(
    Array(isMobile ? items.length + 1 : Math.ceil(items.length / visibleItemsCount) + 1).keys()
  ).slice(1)

  /* auto-play */
  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }
  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => handleNextClick(), 5000)
    return () => resetTimeout()
  }, [activeIndex, isMobile])

  /* if empty */
  if (!items) return null

  /* Return content */
  return (
    !!data.items.length && (
      <div className="x__carousel-module-outer">
        <div className="x__carousel-module">
          {!isMobile && (
            <ArrowIcon direction="left" disabled={activeIndex === 0} onClick={handlePrevClick} />
          )}
          <div ref={carouselRef} className="x__carousel-module__items-wrapper">
            {itemWidth && (
              <div
                className="x__carousel-module__items"
                style={{
                  left: `-${itemWidth * activeIndex}px`,
                  width: `${itemWidth * items.length}px`,
                }}
              >
                {items.map(({ label, copy, path }, i) => (
                  <Item key={i} label={label} copy={copy} path={path} width={itemWidth} />
                ))}
              </div>
            )}
          </div>
          {!isMobile && (
            <ArrowIcon
              direction="right"
              disabled={activeIndex === items.length - 1}
              onClick={handleNextClick}
            />
          )}
        </div>
        <div className="x__carousel-module__index">
          {!!slidesIndex.length &&
            slidesIndex.map((el, index) => (
              <i
                key={index}
                className={classNames('x__indicator', {
                  'x__indicator--active':
                    (isMobile ? index : index * visibleItemsCount) === activeIndex,
                })}
                onClick={() => handleIndexClick(index)}
              />
            ))}
        </div>

        <span className="x__chrome--background" />
      </div>
    )
  )
}

export default CarouselModule
