import React from 'react'
import Img from 'gatsby-image'

import buildImageObject from 'src/js/utils/buildImgObject'

// Styles & Images:
import 'src/components/pages/vip/scss/ZoomImage.scss'

const images = {
  placeholder: {
    aspectRatio: 1.568,
    alt: 'Book a live call with one of our crypto concierge experts',
    path: '/vip/img/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'video-placeholder-ssize',
        width: 640,
      },
      {
        fileName: 'video-placeholder-lsize',
        width: 2400,
      },
    ],
  },
}

// Helpers:
const zoomImageData = {
  heading: 'Zoom Meeting ID: 341-067-0841',
  placeholderName: null,
}

// Partials:
const ZoomLogo = () => (
  <svg className="x__logo" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM5.37573 17.3529V10.3038C5.37623 10.2347 5.39035 10.1664 5.4173 10.1029C5.44424 10.0393 5.48347 9.98165 5.53273 9.93327C5.582 9.88489 5.64033 9.84671 5.70439 9.82092C5.76844 9.79514 5.83696 9.78225 5.906 9.78301H16.178C16.9426 9.77997 17.6771 10.0806 18.2201 10.6188C18.7631 11.1571 19.0702 11.8889 19.0739 12.6535V19.7026C19.0729 19.8415 19.0168 19.9743 18.918 20.0719C18.8192 20.1695 18.6857 20.224 18.5468 20.2233H8.27158C7.50702 20.2264 6.77254 19.9257 6.22953 19.3875C5.68652 18.8492 5.37943 18.1174 5.37573 17.3529ZM23.9701 9.95447L19.728 13.0535V16.9591L23.9701 20.0512C24.275 20.2805 24.6243 20.3567 24.6243 19.728V10.2783C24.6243 9.72585 24.3385 9.64901 23.9701 9.95447Z"
    />
    <defs>
      <title>Zoom logo</title>
    </defs>
  </svg>
)

const UserIcon = () => (
  <svg className="x__user" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fillOpacity="0.24" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.3186 30.5094C35.9512 29.2279 37 27.2364 37 25C37 21.134 33.866 18 30 18C26.134 18 23 21.134 23 25C23 27.2364 24.0488 29.2279 25.6814 30.5094C21.2281 31.9168 18 36.0813 18 41V42H20V41C20 36.0293 24.0293 32 29 32H31C35.9707 32 40 36.0293 40 41V42H42V41C42 36.0813 38.7719 31.9168 34.3186 30.5094ZM25 25C25 22.2386 27.2386 20 30 20C32.7614 20 35 22.2386 35 25C35 27.7614 32.7614 30 30 30C27.2386 30 25 27.7614 25 25Z"
    />
  </svg>
)

const Wrapper = ({ size = 'normal', children }) => (
  <figure className={`x__zoom-image x__zoom-image__wrapper x__zoom-image__wrapper--${size}`}>
    {children}
  </figure>
)

const HeaderSection = ({ title }) => (
  <div className="x__zoom-image__header">
    <ZoomLogo />
    <p className="x__heading">{title}</p>
  </div>
)

const FooterSection = () => (
  <div className="x__zoom-image__footer">
    <div className="x__zoom-image__footer-left">
      <i className="x__icon x__icon--mic" />
      <i className="x__icon x__icon--video" />
    </div>
    <div className="x__zoom-image__footer-center">
      <i className="x__icon x__icon--share" />
    </div>
    <div className="x__zoom-image__footer-right">
      <i className="x__icon x__icon--end" />
    </div>
  </div>
)

// Main component:
const ZoomImage = ({ data = zoomImageData }) => (
  <Wrapper>
    <HeaderSection title={data.heading} />

    <div className="x__zoom-image__content">
      <Wrapper size="smaller">
        <UserIcon />
      </Wrapper>

      <div className="x__zoom-image__image">
        {data.placeholderName && (
          <span className="x__zoom-image__image__caption">{data.placeholderName}</span>
        )}

        <Img
          alt={images.placeholder.alt}
          durationFadeIn={2000}
          fluid={buildImageObject(images.placeholder)}
        />
      </div>
    </div>

    <FooterSection />
  </Wrapper>
)

export default ZoomImage
