import React from 'react'

// Styles & Images:
import 'src/components/pages/vip/scss/PricingModule.scss'

// Helpers:
const priceData = 1
const timeData = 5
const itemsData = []

// Partials:
const Wrapper = ({ children }) => (
  <div className="x__pricing-module x__pricing-module__wrapper">{children}</div>
)

const HeaderSection = ({ title, copy }) => (
  <div className="x__pricing-module__header">
    <p className="x__heading" dangerouslySetInnerHTML={{ __html: title }} />
    {copy && <p className="x__subheading" dangerouslySetInnerHTML={{ __html: copy }} />}
  </div>
)

// Main component:
const PricingModule = ({ price = priceData, oldPrice, duration = timeData, items = itemsData }) => {
  const priceStr = parseInt(price).toFixed(2)
  const oldPriceStr = parseInt(oldPrice).toFixed(0)

  const priceCopyStr = `$ <span>${priceStr}</span>/per ${duration} minute call`
  const oldPriceCopyStr = oldPrice ? `<s>$ ${oldPriceStr}</s>` : ''

  return (
    <Wrapper>
      <HeaderSection title={priceCopyStr} copy={oldPriceCopyStr} />

      <ul className="x__pricing-module__content">
        {items.map((item, i) => (
          <li key={i} className="x__item">
            {item}
          </li>
        ))}
      </ul>
    </Wrapper>
  )
}

export default PricingModule
